@import "react-notifications-component/dist/theme.css";
@import "./highlighter.css";
@import "./toast.css";


@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}


body {
  margin: 0;
  @apply font-sans text-Black dark:text-dark-LightBloom150 text-sm font-normal tracking-wider;
}

pre,
code {
  @apply font-mono;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input {
  border-radius: 0;
}
.centering {
  @apply flex items-center justify-center;
}

.scroll-custom::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
.scroll-custom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
}
.scroll-custom::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 0.25rem;
}

.trapezoid{
  clip-path: polygon(90% 0, 100% 80%, 100% 100%, 0 100%, 0 80%, 10% 0);
}

.custom-no-select {
  -webkit-touch-callout: none; 
  -webkit-user-select: none;  
  -moz-user-select: none; 
  -ms-user-select: none;  
  -webkit-tap-highlight-color: transparent;
  user-select: none;   
}

:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

@layer utilities {
  .map-tiles {
      filter:var(--map-tiles-filter, none);
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #898D91 !important;  
 
}

.swiper-button-prev {
  left: 30px !important; 
}