.indicator__circle {
    stroke: currentColor;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 30ms;
}

.indicator__text{
    fill: currentColor;
    width: 100px;
    min-width: 100px;
}