.spinner{
    display: inline-block;
    width: 46px;
    height: 46px;
    border: 4px solid currentColor;
    border-radius: 50%; 
    border-bottom-color: transparent;
    animation: spin 1s linear infinite;
    
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}