/* JSX start*/
.token.string {
  color: rgb(163, 21, 21);
}

.token.tag.attr-name{
  color: rgb(0, 0, 255);
}

.token.keyword{
  color: rgb(0, 0, 255);
}
.token.keyword.module{
  color: rgb(0, 0, 255);
}

.token.punctuation{
  color: rgb(57, 58, 52);
}

.token.operator{
  color: rgb(57, 58, 52);
}

.token.tag.attr-value{
  color: rgb(163, 21, 21);
}

.token.tag{
  color: rgb(163, 21, 21);
}

.token.tag.punctuation{
  color: rgb(57, 58, 52);
}

.token.tag.script.language-javascript{
  color: rgb(34, 51, 84);
}

.token.tag.script.language-javascript.string{
  color: rgb(141, 200, 145);
}

.token.tag.script.language-javascript.operator{
  color: rgb(136, 198, 190);
}

.token.tag.script.language-javascript.template-string.string{
  color: rgb(141, 200, 145);
}

.token.comment{
  color: #929292;
}
/* JSX end*/

/* JSX dark start*/
.dark .token.string {
  color: rgb(141, 200, 145);
}

.dark .token.tag.attr-name{
  color: rgb(197, 165, 197);
}

.dark .token.keyword{
  color: rgb(197, 165, 197);
}

.dark .token.keyword.module{
  color: rgb(197, 165, 197);
}

.dark .token.maybe-class-name{
  color: rgb(121, 182, 242);
}

.dark .token.number{
  color: rgb(90, 155, 207);
}

.dark .token.parameter.maybe-class-name{
  color: white;
}

.dark .token.punctuation{
  color: rgb(136, 198, 190);
}

.dark .token.operator{
  color: rgb(136, 198, 190);
}

.dark .token.tag.attr-value{
  color: rgb(141, 200, 145);
}

.dark .token.tag{
  color: rgb(252, 146, 158);
}

.dark .token.tag.punctuation{
  color: rgb(136, 198, 190);
}

.dark .token.tag.script.language-javascript.number{
  color: rgb(90, 155, 207);
}

.dark .token.tag.script.language-javascript{
  color: white
}

.dark .token.tag.script.language-javascript.operator{
  color: rgb(136, 198, 190);
}

.dark .token.tag.script.language-javascript.punctuation{
  color: rgb(136, 198, 190);
}

.dark .token.tag.script.language-javascript.script-punctuation.punctuation{
  color: rgb(136, 198, 190);
}

.dark .token.tag.script.language-javascript.string{
  color: rgb(141, 200, 145);
}
/* JSX dark end*/

/* CSS start*/
.token.selector.class{
  color: rgb(212, 169, 74);
}

.token.property{
  color: rgb(212, 100, 72);
}

.token.atrule.rule{
  color: rgb(195, 106, 188);
}

.token.selector{
  color: rgb(212, 169, 74);
}
/* CSS dark end*/

/* CSS dark start*/
.dark .token.selector.class{
  color: rgb(215, 186, 125);
}
.dark .token.property{
  color: rgb(156, 220, 254);
}
.dark .token.atrule.rule{
  color: rgb(197, 134, 192);
}

.dark .token.selector{
  color: rgb(215, 186, 125);
}
/* CSS dark end*/